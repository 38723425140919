<template>
    <b-modal
            @show="$emit('resetModal')"
            @hidden="$emit('hidden')"
            @ok="$emit('handleAuth')"
            centered
            title="احراز هویت"
            ok-title="ارسال"
            cancel-title="بستن"
            ok-variant="success"
            cancel-variant="danger"
            v-model="bankModal">
        <b-card
                img-alt="Card image cap"
                :title="'اطلاعات تطبیقی برای کد ملی : ' + nationalId"
                img-top
        >
            <b-card-body class="p-0">
                <div
                        class="employee-task d-flex justify-content-between align-items-center"
                >
                    <b-media no-body>
                        <b-media-aside class="mr-75">
                            <feather-icon size="20" icon="BoldIcon"/>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h6 class="mb-0">
                                نام بانک در سیستم
                            </h6>
                            <small>نام بانک وارد شده</small>
                        </b-media-body>
                    </b-media>
                    <b-media no-body>
                        <b-media-body class="my-auto text-right">
                            <h6 class="mb-0">
                                {{ bankData.bankName }}
                            </h6>
                            <small>{{ account.bank }}</small>
                        </b-media-body>
                    </b-media>
                </div>
                <div
                        class="employee-task d-flex justify-content-between align-items-center my-50"
                >
                    <b-media no-body>
                        <b-media-aside class="mr-75">
                            <feather-icon size="20" icon="CreditCardIcon"/>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h6 class="mb-0">
                                شماره کارت در سیستم
                            </h6>
                            <small>شماره کارت وارد شده</small>
                        </b-media-body>
                    </b-media>
                    <b-media no-body>
                        <b-media-body class="my-auto text-right">
                            <h6 class="mb-0">
                                {{ bankData.card }}
                            </h6>
                            <small>{{ account.card_number }}</small>
                        </b-media-body>
                    </b-media>
                </div>
                <div
                        class="employee-task d-flex justify-content-between align-items-center my-50"
                >
                    <b-media no-body>
                        <b-media-aside class="mr-75">
                            <feather-icon size="20" icon="CpuIcon"/>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h6 class="mb-0">
                                شبای حساب در سیستم
                            </h6>
                            <small>شبای وارد شده</small>
                        </b-media-body>
                    </b-media>
                    <b-media no-body>
                        <b-media-body class="my-auto text-right">
                            <h6 class="mb-0">
                                {{ bankData.IBAN }}
                            </h6>
                            <small>IR{{ account.sheba }}</small>
                        </b-media-body>
                    </b-media>
                </div>
                <!--                        v-for="depositOwners in bankData.finoTechResponseOfCardToIbanDTO.depositOwners"-->
                <!--                        :key="depositOwners.firstName"-->
                <div
                        class="employee-task d-flex justify-content-between align-items-center my-50"
                >
                    <b-media no-body>
                        <b-media-aside class="mr-75">
                            <feather-icon size="20" icon="UserIcon"/>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h6 class="mb-0">
                                نام صاحب حساب در سیستم
                            </h6>
                            <small>نام وارد شده</small>
                        </b-media-body>
                    </b-media>
                    <b-media no-body>
                        <b-media-body class="my-auto text-right">
                            <h6 class="mb-0">
                                {{ bankData.depositOwners }}
                            </h6>
                            <small>{{ userInfo.profile.name }} {{ userInfo.profile.lastname }}</small>
                        </b-media-body>
                    </b-media>
                </div>
                <div
                        class="employee-task d-flex justify-content-between align-items-center my-50"
                >
                    <b-media no-body>
                        <b-media-body class="my-auto"
                                      :class="['text-' + (bankData.depositStatus === '02' ? 'success' : 'danger'),]">
                            <h6 class="mb-0">
                                وضعیت حساب
                            </h6>
                            <small>{{ depositStatusLabel(bankData.depositStatus) }}</small>
                        </b-media-body>
                    </b-media>
                </div>
            </b-card-body>
        </b-card>
        <p>وضعیت حساب</p>
        <b-form-select :value="account.status" @change="changeStatus" :options="statusOptions"></b-form-select>
    </b-modal>
</template>

<script>
import {
    BModal,
    BCard,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormSelect
} from 'bootstrap-vue'

import {$themeColors} from '@themeConfig'

export default {
    name: "BankModal",
    props: ['bankData', 'bankModal', 'nationalId', 'userInfo', 'account'],
    components: {
        BModal,
        BCard,
        BCardBody,
        BMedia,
        BMediaAside,
        BMediaBody,
        BFormSelect
        // VueApexCharts,
    },
    data() {
        return {
            chartColor: {danger: $themeColors.danger, success: $themeColors.success, warning: $themeColors.warning},
            status: '',
            statusOptions: [
                {value: 'PENDING', text: 'در حال بررسی'},
                {value: "CONFLICT", text: 'عدم تطابق اطلاعات'},
                {value: 'USER_CONFLICT', text: 'عدم تطابق اطلاعات هویتی'},
                {value: "ACCEPTED", text: 'تایید شده'},
            ]
        }
    },
    computed: {
        authColor() {
            return a => a > 90 ? 'success' : a > 60 ? 'warning' : 'danger';
        },
        depositStatusLabel() {
            const status = {
                '02': 'حساب فعال است',
                '03': 'حساب مسدود با قابلیت واریز',
                '04': 'حساب مسدود بدون قابلیت واریز',
                '05': 'حساب راکد است',
                '06': 'بروز خطادر پاسخ دهی , شرح خطا در فیلد توضیحات است',
                '07': 'سایر موارد'
            }
            return e => status[e]
        }
    },
    methods: {
        changeStatus(e) {
            let account = this.account
            account.status = e
            this.$emit('update:account', account)
        }
    },
    created() {
        this.status = this.account.status
    }
}
</script>

<style scoped>

</style>
