<template>
    <b-modal
            @show="$emit('resetModal')"
            @hidden="$emit('hidden')"
            @cancel="$emit('handleAuth',false)"
            @ok="$emit('handleAuth',true)"
            centered
            title="احراز هویت"
            ok-title="تایید هویت"
            cancel-title="عدم انطباق"
            ok-variant="success"
            cancel-variant="danger"
            v-model="authModal">
        <b-card
                :title="'اطلاعات تطبیقی برای کد ملی : ' + nationalId"
                header-class="video"
        >
            <template #header>
                <div class="documents">
                    <div v-for="item in userInfo.documents" :key="item.id">
                        <b-card
                                :title="imageLabel(item.type)"
                                :img-src="item.image"
                                img-top
                                tag="article"
                                class="mb-2 text-center"
                        >
                            <b-button-group v-if="item.status === 'PENDING'">
                                <b-button @click="changeImageStatus(item.id, 'ACCEPTED')" variant="success">تایید
                                </b-button>
                                <b-button @click="changeImageStatus(item.id, 'BLURRED')" variant="info">واضح نیست
                                </b-button>
                                <b-button @click="changeImageStatus(item.id, 'CONFLICTS')" variant="warning">عدم تطابق
                                </b-button>
                            </b-button-group>
                            <span class="text-center" v-else>
                                {{  imageStatusLabel(item.status) }}
                            </span>
                        </b-card>
                    </div>
                </div>
                <!--                <video width="100%" controls>-->
                <!--                    <source :src="link" type="video/mp4">-->
                <!--                </video>-->
            </template>
            <b-card-body class="p-0">
                <div
                        class="employee-task d-flex justify-content-between align-items-center"
                >
                    <b-media no-body>
                        <b-media-aside class="mr-75 align-self-center">
                            <feather-icon size="20" icon="UserIcon"/>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h6 class="mb-0">
                                {{ finoDatas.fullName }}
                            </h6>
                            <small>{{ finoDatas.live }}</small>
                        </b-media-body>
                    </b-media>
                    <div class="d-flex align-items-center">
                        <small class="text-muted mr-75">
                            {{
                                (finoDatas.percent > 90 ? 'تطبیق کامل' : finoDatas.percent > 60 ? 'تطابق نسبی' : 'عدم تطابق') + ' : ' + finoDatas.percent
                            }}
                        </small>

                        <!-- chart -->
                        <vue-apex-charts
                                type="radialBar"
                                height="30"
                                width="30"
                                v-if="finoDatas.options"
                                :options="finoDatas.options"
                                :series="[finoDatas.percent]"
                        />
                    </div>
                </div>
            </b-card-body>
        </b-card>
    </b-modal>
</template>

<script>
import {
    BModal,
    BCard,
    BCardBody,
    // BCardImg,
    // BCardTitle,
    BButtonGroup,
    BButton,
    BMedia,
    BMediaAside,
    BMediaBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import {$themeColors} from '@themeConfig'

const $trackBgColor = '#e9ecef'

export default {
    name: "AuthModal",
    props: ['finoDatas', 'authModal', 'nationalId', 'link', 'userInfo'],
    components: {
        BModal,
        BCard,
        BCardBody,
        BButton,
        BButtonGroup,
        // BCardImg,
        // BCardTitle,
        BMedia,
        BMediaAside,
        BMediaBody,
        VueApexCharts,
    },
    data() {
        return {
            chartColor: {danger: $themeColors.danger, success: $themeColors.success, warning: $themeColors.warning},
        }
    },
    watch: {
        'finoDatas.percent'() {
            this.setChartOption()
        }
    },
    computed: {
        authColor() {
            return a => a > 90 ? 'success' : a > 60 ? 'warning' : 'danger';
        },
        imageStatusLabel() {
            const image = {
                ACCEPTED : 'تایید',
                BLURRED : 'واضح نیست',
                CONFLICTS : 'عدم تطابق اطلاعات'
            }
            return e => image[e]
        } ,
        imageLabel() {
            const image = {
                SELFIE : 'سلفی',
                BANK_CARD : 'کارت بانکی',
                NATIONAL_CARD : 'کارت ملی'
            }
            return e => 'تصویر' + image[e]
        }
    },
    methods: {
        changeImageStatus(id, status) {
            let url = '/documents/' + id
            this.$axios.patch(url, {
                status: status
            })

            this.$swal({
                icon: 'success',
                title: 'درخواست با موفقیت انجام شد.',
            })
            this.$emit('updateData')
        },
        setChartOption() {
            this.finoDatas.options = {
                grid: {
                    show: false,
                    padding: {
                        left: -15,
                        right: -15,
                        top: -12,
                        bottom: -15,
                    },
                },
                colors: [this.chartColor[this.authColor(Number(this.finoDatas.percent))]],
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '22%',
                        },
                        track: {
                            background: $trackBgColor,
                        },
                        dataLabels: {
                            showOn: 'always',
                            name: {
                                show: false,
                            },
                            value: {
                                show: false,
                            },
                        },
                    },
                },
                stroke: {
                    lineCap: 'round',
                },
            }
        }
    },
    created() {
        this.setChartOption()
    }
}
</script>

<style scoped>
.video {
    padding: 0;
    padding-bottom: 1rem;
}

.documents {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: center;
}
</style>
