<template>
    <feather-icon
            icon="AlertTriangleIcon"
            size="20"
            class="text-warning ml-2 cursor-not-allowed"
            v-tooltip="tooltip"
    />
</template>

<script>
    export default {
        name: "NotAllowed",
        props: {
            tooltip: {
                type: Object,
                default: function () {
                    return {
                        variant: 'warning',
                        title: 'عدم دسترسی'
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>