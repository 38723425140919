<template>
    <b-card title="رمز دو عاملی">
        <div class="relative-over-x">
            <b-form @change="$emit('changeSetting', $event.target.value)">
                <b-form-group id="input-group-4" v-slot="{ ariaDescribedby }">
                    <b-form-radio-group v-model="setting"
                                        id="checkboxes-4"
                                        :aria-describedby="ariaDescribedby"
                    >
                        <b-form-radio value="EMAIL">ایمیل</b-form-radio>
                        <b-form-radio value="SMS">پیامک</b-form-radio>
                        <b-form-radio value="GOOGLE">گوگل</b-form-radio>
                    </b-form-radio-group>

                </b-form-group>
            </b-form>
        </div>
    </b-card>
</template>

<script>
import {
    // BAvatar,
    BCard,
    BForm, BFormGroup, BFormRadio, BFormRadioGroup,
    // BMedia,
    // BTable
} from "bootstrap-vue";

export default {
    name: "UserSetting",
    components : {
        BCard,
        BForm,
        BFormGroup,
        BFormRadio,
        BFormRadioGroup,
    },
    data() {
        return  {
            setting : '',
        }
    },
    props : ['settings'],
    mounted() {
        let settingOtp = this.settings.find(i => {
            return i.setting_key === 'OTP'
        })
        this.setting = settingOtp.setting_value
    }
}
</script>

<style scoped>

</style>
