<template>
    <b-card title="دارایی (اعتبار)">
        <div class="relative-over-x">
            <b-table
                    ref="refUserListTable"
                    :items="data"
                    :small="true"
                    responsive
                    :fields="columns"
                    primary-key="id"
                    show-empty
                    empty-text="اطلاعاتی یافت نشد"
                    class="text-nowrap"
                    style="min-height : 235px"
            >
<!--                    :sort-by.sync="sortBy"-->
<!--                    :sort-desc.sync="isSortDirDesc"-->
                <template #cell(relatedCoin)="data">
                    <b-media vertical-align="center">
                        <template #aside>
                            <b-avatar
                                    v-if="data.item.code === 'TOMAN'"
                                    size="32"
                                    :src="require(`@/assets/images/Coins/TOMAN.png`)"
                                    :variant="`light-info`"
                            />
<!--                                    :to="{ name: 'apps-users-view', params: { id: data.item.id } }"-->
                            <cryptoicon v-else
                                        size="32"
                                        :symbol="data.item.code"
                            />
                        </template>

                        {{ data.item.label ? data.item.label : ''  }}
                        <small class="text-muted">{{ data.item.name ? data.item.name : data.item.code }}</small>
                    </b-media>
                </template>
                <template #cell(credit)="data">
                <span dir="ltr">
                    {{ $toLocal(data.item.credit) || 0 }}
                </span>
                </template>

                <template #cell(blocked)="data">
                <span dir="ltr">
                    {{ $toLocal(data.item.blocked) || 0 }}
                </span>
                </template>

            </b-table>
        </div>

    </b-card>
</template>

<script>
import {
    BCard,
    BTable,
    // BBadge,
    BMedia,
    BAvatar,
    // BLink,
} from 'bootstrap-vue'
// import vSelect from 'vue-select';

export default {
    name: 'userWallets',
    components: {
        BCard,
        BTable,
        // BBadge,
        BMedia,
        BAvatar,
        // BLink,
    },
    props: ['data'],
    data: () => ({
        columns: [
            {
                label: 'رمز ارز',
                key: 'relatedCoin',
                sortable: false,
            },
            {
                label: 'موجودی',
                key: 'credit',
                sortable: false,
            },
            {
                label: 'دارایی بلوکه شده',
                key: 'blocked',
                sortable: false,
            },
        ],
    }),
    computed: {
        roleVariant() {
            const a = {
                master: 'danger',
                accounting: 'info',
                technical: 'warning',
                employee: 'secondary'
            }
            return e => a[e];
        },
        roleIcon() {
            const a = {
                master: 'ServerIcon',
                accounting: 'DivideSquareIcon',
                technical: 'SettingsIcon',
                employee: 'UserIcon'
            }
            return e => a[e];
        },
        statusVariant() {
            const a = {
                inactive: 'danger',
                active: 'success',
            }
            return e => a[e];
        },
        statusLabel() {
            const a = {
                inactive: 'غیر فعال',
                active: 'فعال',
            }
            return e => a[e];
        },
    },
}
</script>
<style lang="scss">
[v-cloak] {
  opacity: 0;
}

[dir="rtl"] .test-badge {
  font-size: 10px;
  top: -6px;
  left: -5px !important;
  min-width: 16px;
  min-height: 16px;
}
</style>
